// * React Bootstrap
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const Skills = () => {
  return (
    <section className='skills-wrapper' id="skills">
      <Container className="skills-container">
        <h2>~ SKILLS ~</h2>
        <Row>
          <Col className="col-12" xl="8">
            <h3>Development</h3>
            <div className="dev-skills-container">
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" />
                <p>HTML5</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" />
                <p>CSS3</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" />
                <p>Sass</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" />
                <p>Bootstrap</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
                <p>JavaScript</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
                <p>React.js</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" />         
                <p>Express</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" />                          
                <p>Node.js</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg" />
                <p>MongoDb</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" />                
                <p>Python</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg" />
                <p>Django</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" />        
                <p>PostgreSQL</p>
              </div>
              {/* <div className="skill">
                <i className="devicon-git-plain"></i>
                <p>Git</p>
              </div> */}
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" />
                <p>GitHub</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-original.svg" />
                <p>Heroku</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg" />
                <p>VSCode</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg" />
                <p>Figma</p>
              </div>
            </div>
          </Col>
          <Col className="col-12" xl="4">
            <h3>Marketing & Advertising</h3>
            <div className="other-skills-container">
              <div className="skill">
                <img className="icon google-analytics" src={require('../images/icons/google_analytics.png')} alt="google analytics" />
                <p>Google Analytics</p>
              </div>
              <div className="skill">
                <img className="icon" src={require('../images/icons/google_data_studio.png')} alt="google data studio" />
                <p>Google Data Studio</p>
              </div>
              <div className="skill">
                <img className="icon" src={require('../images/icons/adobe_analytics.png')} alt="adobe analytics" />
                <p>Adobe Analytics</p>
              </div>
              <div className="skill">
                <img className="icon" src={require('../images/icons/datorama.png')} alt="datorama" />
                <p>Datorama</p>
              </div>
              <div className="skill"> 
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-original.svg" />
                <p>LinkedIn Ads</p>
              </div>
              <div className="skill">
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/twitter/twitter-original.svg" />
                <p>Twitter Ads</p>
              </div>
              <div className="skill">
                <img className="icon" src={require('../images/icons/google_adwords.png')} alt="google adwords" />
                <p>Google Ads</p>
              </div>
              <div className="skill">
                <img className="icon" src={require('../images/icons/campaign_monitor1.png')} alt="campaign_monitor" />
                <p>Campaign Monitor</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Skills