// * Hooks
import { useState } from 'react'
// * Components
import Interests from './Interests'
// * React Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// * Images
import profileImg from '../images/profile_pic.png'

const About = () => {

  const [show, setShow] = useState(false)

  const handleClick = () => {
    setShow(current => !current)
    // setShow(true)
  }

  return (
    <section className="about-wrapper" id="about">
      <Container className="about"> 
        <h2>~ ABOUT ~</h2>
        <Row className="about-body d-flex flex-column-reverse flex-xl-row" >
          <Col className="col-12  mt-4 m-xl-0 about-text" xl="8">
            <p>Hi, I&apos;m Milly, a junior software developer based in London.</p>
            <p>Previously, I worked in financial advertising and marketing for 7 years, the majority of which was spent at a specialist financial services media agency where I gained experience in marketing analytics, campaign management and client servicing. I was often happiest when building campaign performance dashboards and working with digital and development teams so I decided to further explore the world of web development. Ultimately, this led me to enrolling in the General Assembly Software Engineering Immersive course where I gained a great understanding of full stack development.</p>
            <p>I am now excited to stretch my abilities and eager to join a dynamic and collaborative team in a company where I can grow and build upon my current skill set.</p>
          </Col>
          <Col className="col-12 profile-image d-flex justify-content-center" xl="4">
            <img src={profileImg} alt="profileImg" />
          </Col>
        </Row>
        {/* <Row className="d-flex justify-content-center">
          <button onClick={handleClick}>{!show ? 'A little more about me...' : 'Less about me...'}</button>
        </Row> */}
        {/* {show && <Interests />} */}
      </Container>
    </section>
  )
}

export default About