// * Hooks & Components
import { useState } from 'react'
import { Link } from 'react-router-dom'
// * React Bootstrap
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// * Scroller
import { scroller } from 'react-scroll'

const PageNavBar = () => {
  const  [expanded, setExpanded] = useState(false)

  const mobileScrollSettings = {
    smooth: true,
    offset: -245,
    duration: 500,
    isDynamic: true,
  }

  const scrollSettings = {
    smooth: true,
    offset: -70,
    duration: 500,
    isDynamic: true,
  }

  return (
    <Navbar expand="md" expanded={expanded}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : 'expanded')}></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center pt-3 pt-md-0">
          <Nav.Link onClick={() => {
            if (expanded) {
              scroller.scrollTo('about', {
                ...mobileScrollSettings,
              })
            } else {
              scroller.scrollTo('about', {
                ...scrollSettings,
              })
            }
            setExpanded(false)
          }}>
            <span className="about underline mx-4">ABOUT</span>
          </Nav.Link>
          <Nav.Link onClick={() => {
            if (expanded) {
              scroller.scrollTo('skills', {
                ...mobileScrollSettings,
              })
            } else {
              scroller.scrollTo('skills', {
                ...scrollSettings,
              })
            }
            setExpanded(false)
          }}>
            <span className="underline mx-4">SKILLS</span>
          </Nav.Link>
          <Nav.Link onClick={() => {
            if (expanded) {
              scroller.scrollTo('projects', {
                ...mobileScrollSettings,
              })
            } else {
              scroller.scrollTo('projects', {
                ...scrollSettings,
              })
            }
            setExpanded(false)
          }}>
            <span className="underline mx-4">PROJECTS</span>
          </Nav.Link>
          <Nav.Link onClick={() => {
            if (expanded) {
              scroller.scrollTo('experience', {
                ...mobileScrollSettings,
              })
            } else {
              scroller.scrollTo('experience', {
                ...scrollSettings,
              })
            }
            setExpanded(false)
          }}>
            <span className="underline mx-4">EXPERIENCE</span>
          </Nav.Link>
          <Nav.Link onClick={() => {
            if (expanded) {
              scroller.scrollTo('contact', {
                ...mobileScrollSettings,
              })
            } else {
              scroller.scrollTo('contact', {
                ...scrollSettings,
              })
            }
            setExpanded(false)
          }}>
            <span className="underline mx-4">CONTACT</span>
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default PageNavBar


