import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PageNavBar from './components/PageNavBar'
import Title from './components/Title'
import About from './components/About'
import Skills from './components/Skills'
import Experience from './components/Experience'
import Projects from './components/Projects'
import Contact from './components/Contact'

const App = () => {
  return (
    <div className="site-wrapper">
      <BrowserRouter>
        <PageNavBar/>
        <Title/>
        <About/>
        <Skills/>
        <Projects/>
        <Experience/>
        <Contact/>
      </BrowserRouter>
    </div>
  )
}

export default App
