// * React Bootstrap
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// *React Slick
import Slider from 'react-slick'
// * aromatics images
import aromaticsHomepage from '../images/projects/aromatics/homepage.jpeg'
import eoIndexImg from '../images/projects/aromatics/essential_oil_index.jpeg'
import boIndexImg from '../images/projects/aromatics/base_oil_index.jpeg'
import recipeIndexImg from '../images/projects/aromatics/recipe_index.jpeg'
import eoImg from '../images/projects/aromatics/essential_oil_example.jpeg'
import boImg from '../images/projects/aromatics/base_oil_example.jpeg'
import recipeImg from '../images/projects/aromatics/recipe_examples.jpeg'
import createRecipeImg from '../images/projects/aromatics/create_recipe.jpeg'
import editRecipeImg from '../images/projects/aromatics/edit_recipe.jpeg'
import savedRecipesImg from '../images/projects/aromatics/your_saved_recipes.jpeg'
import createdRecipesImg from '../images/projects/aromatics/created_recipes.jpeg'
// * Watchit images
import watchItSwipe from '../images/projects/watchit_swipe_resize.jpeg'
import watchItMovie from '../images/projects/watchit_movie.jpeg'
import watchItMovieMatch from '../images/projects/watchit_moviematch.jpeg'
import watchItMatchWith from '../images/projects/watchit_matchwith.jpeg'
// * Formula1 images
import standingsImg from '../images/projects/formula1/current_standings.jpeg'
import driverProfileImg from '../images/projects/formula1/driver_profile.jpeg'
import raceResultsImg from '../images/projects/formula1/race_results.jpeg'
import scheduleImg from '../images/projects/formula1/schedule.jpeg'
// * PacMan images
import pacmanImg from '../images/projects/pacman/pacman.jpeg'
import mythImg from '../images/projects/pacman/theseus_myth.jpeg'




const Projects = () => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 3000,
  }
  const sliderSettingsMobile = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  }

  return (
    <div className="project-wrapper" id="projects">
      <Container>
        <h2>~ PROJECTS ~</h2>
        <div className="project aromatics mb-4">
          <Row>
            <Col className="col-12" xl="6">
              <div className="project-screen">
                <div className="slider-container">
                  <Slider {...sliderSettings}>
                    <div className="slider-image-container">
                      <img src={aromaticsHomepage} alt="homepage"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={eoIndexImg} alt="essential oils index"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={eoImg} alt="essential oil"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={boIndexImg} alt="base oils index"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={boImg} alt="base oil"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={recipeIndexImg} alt="recipe index"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={recipeImg} alt="recipe"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={createRecipeImg} alt="create recipe"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={editRecipeImg} alt="edit recipe"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={createdRecipesImg} alt="created recipes"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={savedRecipesImg} alt="saved recipes"/>
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
            <Col className="col-12 py-2 px-xl-3 pe-xxl-5" xl="6">
              <h3>aromatics</h3>
              <p>Pair Project | 9 days</p>
              <div className="project-skills">
                <i className="devicon-javascript-plain"></i>
                <i className="devicon-react-original"></i>
                <i className="devicon-python-plain"></i>
                <i className="devicon-django-plain"></i>          
                <i className="devicon-postgresql-plain"></i>          
                <i className="devicon-bootstrap-plain"></i>
              </div>
              <p>We built an aromatherapy application using React and Django/PostgreSQL for the backend. The app contains information on essential oils, base oils and has a collection of recipes. Users can login to create, edit or delete their own recipes and choose whether to make them public or private. Users can also rate, comment and save other users&apos; recipes.</p>
              <div className="button-links">
                <button>
                  <i className="fa-solid fa-link"></i>          
                  <a href="https://sei-p4-aromatics.herokuapp.com/" target="_blank" rel="noreferrer">Open project
                  </a>
                </button>
                <button>
                  <i className="devicon-github-original github-icon"></i>
                  <a href="https://github.com/millyarkwright/sei-project-04" target="_blank" rel="noreferrer">            
                  View on GitHub
                  </a>
                </button>
              </div>
            </Col>
          </Row>
        </div>

        {/* Watchit */}
        <div className="project watchit mb-4">
          <Row>
            <Col className="col-12" xl="6">
              <div className="project-screen">
                <div className="slider-container">
                  <Slider {...sliderSettings}>
                    <div className="slider-image-container">
                      <img src={watchItSwipe} alt="swipe"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={watchItMovie} alt="movie"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={watchItMatchWith} alt="match with"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={watchItMovieMatch} alt="movie"/>
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
            <Col className="col-12 py-2 px-xl-3 pe-xxl-5" xl="6">
              <h3>Watchit</h3>
              <p>Group Project | One week</p>
              <div className="project-skills">
                <i className="devicon-javascript-plain"></i>
                <i className="devicon-react-original"></i>
                <i className="devicon-mongodb-plain-wordmark"></i>
                <i className="devicon-express-original"></i>
                <i className="devicon-nodejs-plain"></i>
                <i className="devicon-bootstrap-plain"></i>
              </div>
              <p>Watchit is a MERN application that generates a random movie based on the preferences of two users. User can create an account and after setting their movie preferences by swiping through our selection of movies (IMDb Top 250 Movies), they can select a user to &quot;match&quot; with and the app will suggest a movie to watch that both users have liked.</p>
              <div className="button-links">
                <button>
                  <i className="fa-solid fa-link"></i>          
                  <a href="https://sei-project3-moviematch.netlify.app/" target="_blank" rel="noreferrer">Open project
                  </a>
                </button>
                <button>
                  <i className="devicon-github-original github-icon"></i>
                  <a href="https://github.com/millyarkwright/sei-project-03-fe" target="_blank" rel="noreferrer">            
                  View on GitHub
                  </a>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        {/* Formula1 */}
        <div className="project formula1 mb-4"> 
          <Row>
            <Col className="col-12" xl="6">
              <div className="project-screen">
                <div className="slider-container">
                  <Slider {...sliderSettings}>
                    <div className="slider-image-container">
                      <img src={standingsImg} alt="standings"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={scheduleImg} alt="schedule"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={raceResultsImg} alt="race results"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={driverProfileImg} alt="driver profile"/>
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
            <Col className="col-12 py-2 px-xl-3 pe-xxl-5" xl="6">
              <h3>Formula 1</h3>
              <p>Pair Project | 3 days</p>
              <div className="project-skills">
                <i className="devicon-javascript-plain"></i>
                <i className="devicon-react-original"></i>
                <i className="devicon-bootstrap-plain"></i>
                <i className="devicon-css3-plain-wordmark"></i>
                <i className="devicon-sass-original"></i> 
              </div>
              <p>We created a frontend React application that consumed two public APIs. The app displays current driver standings, race schedule and results, the drivers index, and driver profiles. Spending two days sharing our different strengths and skills as well as learning to pair code was invaluable. Outside of paircoding, my responsibilities included the Driver Profiles and Schedule pages.</p>
              <div className="button-links">
                <button>
                  <i className="fa-solid fa-link"></i>          
                  <a href="https://sei-project2-formula1.netlify.app" target="_blank" rel="noreferrer">Open project
                  </a>
                </button>
                <button>
                  <i className="devicon-github-original github-icon"></i>
                  <a href="https://github.com/millyarkwright/sei-project2-Formula1" target="_blank" rel="noreferrer">            
                  View on GitHub
                  </a>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        {/* Pacman */}
        <div className="project pacman mb-4">
          <Row>
            <Col className="col-12" xl="6">
              <div className="project-screen">
                <div className="slider-container">
                  <Slider {...sliderSettings}>
                    <div className="slider-image-container">
                      <img src={pacmanImg} alt="pacman"/>
                    </div>
                    <div className="slider-image-container">
                      <img src={mythImg} alt="myth"/>
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
            <Col className="col-12 py-2 px-xl-3 pe-xxl-5" xl="6">
              <h3>Theseus & the Minotaur Pac-Man</h3>
              <p>Individual Project | One week</p>
              <div className="project-skills">
                <i className="devicon-javascript-plain"></i>
                <i className="devicon-html5-plain-wordmark"></i>
                <i className="devicon-css3-plain-wordmark"></i>
              </div>
              <p>For our first project, we were tasked with creating grid-based game using HTML, CSS and JavaScript technologies that we had learnt in the first three weeks of the course. As a fan of Greek mythology, my game was Theseus & the Minotaur themed Pac-Man. </p>
              <div className="button-links">
                <button>
                  <i className="fa-solid fa-link"></i>          
                  <a href="https://millyarkwright.github.io/pac-man" target="_blank" rel="noreferrer">Open project
                  </a>
                </button>
                <button>
                  <i className="devicon-github-original github-icon"></i>
                  <a href="https://github.com/millyarkwright/pac-man" target="_blank" rel="noreferrer">            
                  View on GitHub
                  </a>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Projects