// * React Bootstrap
import Container from 'react-bootstrap/Container'
// * Images
import gaImg from '../images/experience/general_assembly1.png'
import ptarmiganImg from '../images/experience/ptarmigan1.jpeg'
import quotedDataImg from '../images/experience/quoteddata1.png'



const Experience = () => {
  return (
    <section className="experience-wrapper" id="experience">
      <Container>
        <h2>~ EXPERIENCE ~</h2>
        <div className="experience-container">
          <div className="experience flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">              
                <img src={gaImg} alt="generalassembly"/>
                <h3>General Assembly</h3>
                <h4>Software Engineering Student</h4>
                <p>June 2022 - September 2022</p>
              </div>                
              <div className="flip-card-back">
                <p>12-week immersive course that covered the fundamentals of Web Development from the frontend through to the backend. Involved daily stand-ups, online-lessons, labs and pair coding, and four projects (solo, pair and group).</p>
              </div>
            </div>
          </div>
          <div className="experience flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={quotedDataImg} alt="quoteddata"/>
                <h3>QuotedData, Marten & Co</h3>
                <h4>Senior Marketing Manager</h4>
                <p>December 2021 - June 2022</p>
              </div>
              <div className="flip-card-back">
                <p>Led the execution of digital as well as offline marketing channels including; email marketing, content marketing, social media and events (virtual and in person).</p>
              </div>
            </div>
          </div>
          <div className="experience flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={ptarmiganImg} alt="ptarmigan"/>
                <h3>Ptarmigan Media</h3>
                <h4>Account Director & Digital Strategist</h4>
                <p>May 2015 - November 2021</p>
              </div>
              <div className="flip-card-back">
                <p>Led, managed and worked with global and cross-functional teams to execute global advertising campaigns for clients such as Capital Group, AXA XL, Vanguard, Robeco and Schroders.</p>
                <p>Built and maintained real-time data dashboards and generated campaign performance reports.</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>

  )
}

export default Experience