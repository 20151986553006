// * React Bootstrap
import Container from 'react-bootstrap/Container'

const Contact = () => {
  return (
    <section className="contact-wrapper" id="contact">
      <Container className="contact"> 
        <h2 className="mb-2">~ CONTACT ~</h2>
        <p>Please do get in touch if you like my work or would like more information.</p>
        <p className="my-2 fst-italic">milly.arkwright@gmail.com</p>
        <div className="contact-icons">
          <a href="https://github.com/millyarkwright" target="_blank" rel="noreferrer">
            <i className="devicon-github-original"></i>
          </a>
          <a href="https://www.linkedin.com/in/milly-arkwright/" target="_blank" rel="noreferrer">
            <i className="devicon-linkedin-plain"></i>
          </a>
          <a href="mailto:milly.arkwright@gmail.com">
            <i className="fa-solid fa-envelope"></i>          
          </a>
        </div>
      </Container>
    </section>
  )
}

export default Contact