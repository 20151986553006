// * React Bootstrap 
import Container from 'react-bootstrap/Container'

const Title = () => {
  return (
    <>
      <section className="title-wrapper">
        <Container className="title-container"> 
          <div className="title p-4 p-md-5 ">
            <h1>MILLY ARKWRIGHT</h1>
            <h2 className="mt-3">~ Junior Software Developer ~</h2>
          </div>
          <i className="fa-solid fa-angles-down"></i>      
        </Container>
      </section>
    </>
  )
}

export default Title